import styled from '@emotion/styled'
import peg from 'src/pegTheme'

export const Title = styled.h1`
  max-width: 800px;
  text-align: ${ props => props.left ? 'left' : 'center' };
  line-height: 130%;
  font-weight: ${ peg.get('fontWeight.light') };
  font-size: ${ peg.fs('title') };
  margin: ${ peg.spacing('xl') } 0 ${ peg.spacing('xl') };
`

export const SubHeading = styled.h2`
  margin-bottom: ${ peg.spacing('s') };
  text-align: ${ props => props.left ? 'left' : 'center' };
  font-size: ${ peg.fs('headLg') };
`

export const StrapLine = styled.h4`
  color: ${ peg.color('grey') };
  margin-top: ${ peg.spacing('s') };
  margin-bottom: ${ peg.spacing('m') };
  max-width: 600px;
  text-align: center;
  line-height: 180%;
  font-size: ${ peg.fs('xl') };
`