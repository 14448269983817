import React from 'react'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import urls from 'src/urls.json'

import logo from 'src/images/logo.svg'
import { Link } from 'gatsby'

export default () => (
  <FooterContainer>
    <FooterContent>
      <BrandInfo>
        <Logo src={ logo } />
        <Copyright>
          © Pegco Ltd { new Date().getFullYear() }
        </Copyright>
      </BrandInfo>
    </FooterContent>
  </FooterContainer>
)

const FooterContainer = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 4px;
  ${ responsive('padding', { d: `${ peg.spacing('xl') } 0`, m: peg.spacing('m') }) }
`

const FooterContent = styled.div`
  ${ responsive('width', { d: '1200px', m: '100%' }) }
  ${ responsive('flex-direction', { d: 'row', m: 'column' }) }
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const BrandInfo = styled.div`
  ${ responsive('display', { d: 'flex', m: 'none' }) }
  flex-direction: column;
  align-items: flex-start;
`

const Logo = styled.img`
  height: 30px;
  margin-bottom: ${ peg.spacing('s') }
`

const Copyright = styled.span`
  font-size: ${ peg.fs('s') };
  color: ${ peg.color('grey') };
`

const FooterLinks = styled.div`
  ${ responsive('width', { d: '30%', m: '100%' }) }
  ${ responsive('margin-top', { d: '0', m: peg.spacing('l') }) }
  display: flex;
  ${ responsive('justify-content', { d: 'space-between', m: 'space-around' }) }
  max-width: 600px;
`

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  ${ responsive('width', { d: 'auto', m: '40%' }) }
`

const ListHeader = styled.span`
  ${ responsive('font-size', { d: peg.fs('m'), m: peg.fs('s') }) }
  color: ${ peg.color('grey.dark') };
  text-transform: uppercase;
  margin-bottom: ${ peg.spacing('m') };
`

const FooterLink = styled.a`
  font-weight: ${ peg.get('fontWeight.normal') };
  color: ${ peg.color('grey') };
  font-size: ${ peg.fs('s') };
  margin-bottom: ${ peg.spacing('s') };
  max-width: 150px;
  line-height: 150%;
`

const FooterInternalLink = FooterLink.withComponent(Link)