import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import peg, { responsive } from 'src/pegTheme'

import logo from 'src/images/logo-white-on-blue.svg'
import checkMobile from './checkMobile'
import { Link } from 'gatsby'
import OutlineButton from './buttons/OutlineButton'
import Headroom from 'react-headroom'
import urls from 'src/urls.json'

const Navbar = () => {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'peg.co'
  const loginUrl = hostname.endsWith('localhost') ? `https://app.${hostname}/` : urls.login

  return (<Headroom pinStart={ 200 } downTolerance={ 5 } disableInlineStyles>
    <NavbarContainer>
      <NavbarSection>
        <LogoLink to='/'>
          <Logo src={ logo } />
        </LogoLink>

        <p><a href='http://current.tech/'>Current</a> has acquired the Peg platform! <Link to='/current'>Read more →</Link></p>
      </NavbarSection>

      <Global
        styles={css`
          body {
            padding-top: 60px;
            @media(max-width: 500px) {
              padding-top: 100px;
            }
          }
        `}
      />
    </NavbarContainer>
  </Headroom>)
}

const NavbarContainer = styled.div`
  border-bottom: 1px solid ${ peg.color('grey.light') };
  height: 60px;
  @media(max-width: 500px) {
    height: 100px;
  }
  padding: 0 ${ peg.spacing('xl') };
  background: ${ peg.color('blue') };
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 3;
  transition: all 0.8s ease;

  .headroom--unpinned & {
    transform: translateY(-60px);
    @media(max-width: 500px) {
      transform: translateY(-100px);
    }
  }

  .headroom--pinned & {
    transform: translateY(0px);
  }
`

const NavbarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #fff;

  p, p a {
    color: #fff;
  }

  p a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: border-bottom 300ms ease;

    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }
`

const minimalLink = css`
  font-size: ${ peg.fs('m') };
  display: inline-flex;
  align-items: center;
  padding: 0 ${ peg.spacing('l') };
  color: ${ peg.color('grey') };
  font-weight: ${ peg.get('fontWeight.bold') };
  -webkit-font-smoothing: antialiased;
  position: relative;
  bottom: -1px;
  transition: color 0.3s ease;

  &:hover {
    color: ${ peg.color('blue') };
  }
`

const LogoLink = styled(Link)`
  position: absolute;
  left: 0;

  @media(max-width: 1000px) {
    display: none;
  }
`

const Logo = styled.img`
  height: 30px;
  margin-right: ${ peg.spacing('l') };
  cursor: pointer;
  vertical-align: middle;
`

const OutlineLink = styled(OutlineButton.withComponent('a'))`
  margin-left: ${ peg.spacing('m') };
`

const DesktopOnly = styled.div`
  ${ responsive('display', { d: 'block', m: 'none' }) }
`

export default checkMobile(Navbar)
