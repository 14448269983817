import styled from '@emotion/styled'
import peg from 'src/pegTheme'

export default styled.button`
  padding: ${ peg.spacing('s') } ${ peg.spacing('m') };
  border: 1px solid ${ peg.color('blue') };
  background-color: transparent;
  border-radius: ${ peg.get('borderRadius') };
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  font-weight: ${ peg.get('fontWeight.bold') };
  font-size: ${ peg.fs('m') };
  color: ${ peg.color('blue') };
  -webkit-font-smoothing: antialiased;
  font-family: inherit;

  &:hover {
    color: #fff;
    background-color: ${ peg.color('blue') };
  }
`