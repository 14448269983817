import React from 'react'
import Navbar from 'src/components/Navbar'
import Footer from 'src/components/Footer'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import { Title, StrapLine } from 'src/components/typography'

export default ({ children, bg }) => (
  <div>
    <Helmet>
      <title>YouTube & Instagram Influencer Marketing Tool & Platform | Peg</title>
    </Helmet>

    <Wrapper bg={ bg }>
      <Navbar />
      <Body>
        { children }
      </Body>
      <Footer />
    </Wrapper>
  </div>
)

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background: ${ props => props.bg || '#fff' };
`

const Body = styled.div`
  flex-grow: 1;
`

export const BaseContainer = styled.div`
  ${ responsive('width', { d: '1200px', m: '100%' }) }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const Container = styled(BaseContainer)`
  ${ responsive('padding', { d: '0', m: `0 ${ peg.spacing('m') }` }) }
`

export const RaisedArea = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 4px;
  margin-bottom: ${ peg.spacing('l') };

  ${ Title } {
    margin: ${ peg.spacing('m') } 0 0;
  }

  ${ StrapLine } {
    margin-top: 0;
  }
`

export const RaisedContainer = styled(BaseContainer)`
  padding: ${ peg.spacing('xl') };
  align-items: center;
`

export const CardsContainer = styled(BaseContainer)`
  flex-flow: row wrap;
  margin-bottom: ${ peg.spacing('xl') };
`