import DesignSystem from 'design-system-utils'
import { compact, camelCase } from 'lodash'
import { css } from '@emotion/core'
import facepaint from 'facepaint'

const ds = new DesignSystem({
  type: {
    baseFontSize: '16px',
    sizes: {
      title: '40px',
      headLg:  '32px',
      headSm: '24px',
      xl: '18px',
      l: '16px',
      m: '14px',
      s: '12px',
      xs: '10px',
    },
  },
  colors: {
    colorPalette: {
      blue: {
        base: '#51c6e5',
        dark: '#28a7c8'
      },
      grey: {
        base: '#6b6c70',
        dark: '#111423',
        light: '#e6e6e6',
        lighter: '#f4f4f4'
      },
      green: { base: '#58d1ab' },
      orange: { base: '#ffba36' },
      yellow: { base: '#ffda34' },
      red: { base: '#fe2553' }
    }
  },
  breakpoints: {
    mobile: '1200px'
  },
  spacing: {
    scale: {
      xs: '5px',
      s: '10px',
      m: '15px',
      l: '20px',
      xl: '40px',
    }
  },
  borderRadius: '4px',
  fontWeight: {
    light: 100,
    normal: 300,
    bold: 500,
  }
})

export default ds

const mq = facepaint([
  `@media(min-width: ${ ds.bp('mobile') })`
])

export function responsive(property, { d: desktop, m: mobile }) {
  return css(mq({
    [camelCase(property)]: compact([mobile, desktop].map(val => val === 0 ? '0px' : val))
  }))
}
